import { Action } from '@ngrx/store';

/**
 * Config loading.
 * UI will trigger an async call and loader should turn on
 */
export const START_LOADING = '[Config] START LOADING';
export const STOP_LOADING = '[Config] STOP LOADING';
export const CONFIG_FAILURE = '[Config] CONFIG FAILURE';

export class StartLoadingAction implements Action {
  readonly type = START_LOADING;

  constructor() { }
}

export class StopLoadingAction implements Action {
  readonly type = STOP_LOADING;

  constructor() { }
}

export class ConfigFailureAction implements Action {
  readonly type = CONFIG_FAILURE;

  constructor() { }
}


export type All =
  | StartLoadingAction
  | StopLoadingAction
  | ConfigFailureAction;
