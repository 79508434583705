import { Action } from '@ngrx/store';
import { UserResponse } from '../../model/user.response';
import { SystemConfig } from '../../model/system-config.response';

/**
 * User login store.
 * UI will call API and
 * return all information that is associated to that user if authenticated.
 */
export const LOGIN = '[User] LOGIN';
export const LOGOUT = '[User] LOGOUT';
export const LOGOUT_SUCCESS = '[User] LOGOUT_SUCCESS';
export const UPDATE_PASSWORD = '[User] UPDATE PASSWORD';
export const LOGIN_SUCCESS = '[User] LOGIN SUCCESS';
export const CHOOSE_CLIENT = '[User] CHOOSE CLIENT';
export const CHOOSE_CLIENT_SUCCESS = '[User] CHOOSE CLIENT SUCCESS';
export const SET_APP_VERSION = '[User] SET APP VERSION';
export const LOGIN_FAILURE = '[User] LOGIN FAILURE';
export const UPDATE_VERSION = '[User] UPDATE APP VERSION';
export const RESET_SESSION = '[User] RESET SESSION';

export class LogInAction implements Action {
  readonly type = LOGIN;

  constructor(
    public payload: {
      username: string;
      password: string;
      admin?: boolean;
      captcha: string;
    }
  ) { }
}

export class LogOutAction implements Action {
  readonly type = LOGOUT;

  constructor(
    public payload: { newVersion: boolean; version: string } = {
      newVersion: false,
      version: ''
    }
  ) { }
}

export class ChooseClientAction implements Action {
  readonly type = CHOOSE_CLIENT;

  constructor(public payload: string) { }
}

export class ChooseClientSuccessAction implements Action {
  readonly type = CHOOSE_CLIENT_SUCCESS;

  constructor(public payload: SystemConfig) { }
}

export class LogOutSuccessAction implements Action {
  readonly type = LOGOUT_SUCCESS;

  constructor() { }
}

export class UpdateVersionAction implements Action {
  readonly type = UPDATE_VERSION;

  constructor(public payload: string) { }
}

export class SetAppVersionAction implements Action {
  readonly type = SET_APP_VERSION;

  constructor(public payload: string) { }
}

export class UpdatePasswordAction implements Action {
  readonly type = UPDATE_PASSWORD;

  constructor(public payload: { response: UserResponse; admin: boolean }) { }
}

export class LogInSuccessAction implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public payload: { response: UserResponse; admin: boolean }) { }
}

export class LogInFailureAction implements Action {
  readonly type = LOGIN_FAILURE;

  constructor(public payload: any) { }
}

export class ResetSessionAction implements Action {
  readonly type = RESET_SESSION;

  constructor() { }
}


export type All =
  | LogInAction
  | LogOutAction
  | LogInSuccessAction
  | LogInFailureAction
  | ResetSessionAction
  | LogOutSuccessAction
  | SetAppVersionAction
  | UpdateVersionAction
  | UpdatePasswordAction
  | ChooseClientAction
  | ChooseClientSuccessAction;
