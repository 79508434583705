import { Injectable } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common';
import { ApiService } from './api.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';


@Injectable()
export class ConfigService {

  public loading$ = new BehaviorSubject<{ loading: boolean, hasError: boolean, hasMsg: string }>
    ({ loading: false, hasError: false, hasMsg: '' });

  public initialloading$ = new BehaviorSubject<{ loading: boolean, hasError: boolean, hasMsg: string }>
    ({ loading: false, hasError: false, hasMsg: '' });

  public error$ = new BehaviorSubject<{ status: string, message: string, show: boolean }>
    ({ status: '', message: '', show: false });

  public newVersion$ = new BehaviorSubject<{ status: string, message: string, show: boolean }>
    ({ status: '', message: '', show: false });

  public consoleLogs$ = new BehaviorSubject<string>('');

  constructor(private router: Router, private platformLocation: PlatformLocation) {
  }

  /**
 * Request interceptor.
 */
  public requestInterceptor(): void {
    // this.loaderService.showPreloader();
    this.loading$.next({
      loading: true, hasError: false, hasMsg: ''
    });
  }

  /**
  * Response interceptor.
  */
  public responseInterceptor(): void {
    this.loading$.next({
      loading: false, hasError: false, hasMsg: ''
    });
  }

  /**
* Request initial loading.
*/
  public initialLoadingStart(): void {
    // this.loaderService.showPreloader();
    this.initialloading$.next({
      loading: true, hasError: false, hasMsg: ''
    });
  }

  /**
  * Response initial loading.
  */
  public initialLoadingStop(): void {
    this.initialloading$.next({
      loading: false, hasError: false, hasMsg: ''
    });
  }


  public displayErrorMessage(status: number, text: string) {
    this.error$.next({
      status: status.toString(), message: text, show: true
    });
  }

  public hideErrorMessage() {
    this.error$.next({
      status: '', message: '', show: false
    });
  }

  public displayNewVersionMessage() {
    this.newVersion$.next({
      status: '', message: 'Application has been updated to the latest version', show: true
    });
  }

  public hideNewVersionMessage() {
    this.newVersion$.next({
      status: '', message: '', show: false
    });
  }

  public getCurrentUrl() {
    let url = '';
    if (!environment.baseHref) {
      const hostName = this.extractHostname(window.location.hostname);
      const hs = this.splitHostname(hostName);
      const currentUrl = `${hs.subdomain}.${hs.domain}.${hs.type}`;
      url = `${window.location.protocol}//${currentUrl}/login`;
    } else {
      const tree = this.router.parseUrl((this.platformLocation as any).location.pathname);
      const baseHref = tree.root.children.primary.segments[0].path;
      url = `${window.location.origin}/${baseHref}/login`;
    }
    return url;
  }

  public getApiUrl() {
    let url = '';
    if (!environment.baseHref) {
      const hostName = this.extractHostname(window.location.hostname);
      const hs = this.splitHostname(hostName);
      const apiUrl = `${hs.subdomain}${hs.domain}${hs.type}.sgdavinci.com`;
      url = `${window.location.protocol}//${apiUrl}`;
    } else {
      url = environment.apiUrl;
    }
    return url;
  }

  public getDomainAndTypeFromUrl() {
    const hostName = this.extractHostname(window.location.hostname);
    const hs = this.splitHostname(hostName);
    const apiUrl = `${hs.subdomain}${hs.domain}${hs.type}.sgdavinci.com`;
    return `${hs.domain}${hs.type}`;
  }

  private extractHostname(url) {
    let hostname = '';
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];
    return hostname;
  }

  private splitHostname(hostName): any {
    const result = {
      domain: '',
      type: '',
      subdomain: ''
    };
    const regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
    const urlParts = regexParse.exec(hostName);
    result.domain = urlParts[1];
    result.type = urlParts[2];
    result.subdomain = window.location.hostname.replace(result.domain + '.' + result.type, '').slice(0, -1);

    return result;
  }

  public getDateLocale(language: string) {
    if (language === 'en-US') {
      return 'en';
    } else if (language === 'en-CA') {
      return 'en';
    } else if (language === 'fr-CA') {
      return 'fr';
    }
  }

}


// Graveyard code
// return new Promise<boolean>((resolve) => {
//   const url = location.pathname;
//   setTimeout(() => {
//     resolve(true);
//   }, 4000);
//   return this.http.get('../../../assets/everything.json').toPromise;
// });
