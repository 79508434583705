import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from './store/reducers';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  loading = false;

  constructor(public store$: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.store$.select(store => store.configState.loading).subscribe(loading => {
      this.loading = loading;
    });
  }


}
