import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { UpdatePdfComponent } from './modals/updatepdf/updatepdf.component';
import { UpdateImageComponent } from './modals/updateimage/updateimage.component';
import { AddImageComponent } from './modals/addimage/addimage.component';
import { DeleteImageComponent } from './modals/deleteimage/deleteimage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResourceCardComponent } from './modals/resource-card/resource-card.component';



@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    NgbModule,
    UpdatePdfComponent,
    UpdateImageComponent,
    AddImageComponent,
    DeleteImageComponent,
    ResourceCardComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    UpdatePdfComponent,
    UpdateImageComponent,
    AddImageComponent,
    DeleteImageComponent,
    ResourceCardComponent
  ]
})
export class SharedModule { }
