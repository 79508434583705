import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import * as UserActions from '../../store/user/user.actions';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
    toggleClass = 'ft-maximize';
    application = '';
    isCollapsed = true;

    constructor(private store$: Store<fromRoot.State>) {
      this.store$.pipe(
        select(store => store.userState.systemConfig)
      ).subscribe(data => {
          this.application = data.DavinciApplicationName;
      })
    }


    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else {
            this.toggleClass = 'ft-maximize'
        }
    }

    logout() {
        this.store$.dispatch(new UserActions.LogOutAction);
    }
}
