export class ResourceLinksResponse {
    displayName: string;
    links: Card[];
    summary: string;
}

export class Card {
    resourceContextId: string;
    type: string;
    paragraphText: string;
    linkTitle: string;
    link: string;
}
