import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../core/services/modal.service';
import { GetAllImages } from '../../../model/getallimages.response';
import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-updateimage-popup',
  templateUrl: './updateimage.component.html',
  styleUrls: ['./updateimage.component.scss']
})
export class UpdateImageComponent implements OnInit, OnDestroy {


  @ViewChild('content', { static: true }) private content;
  openTimeoutModalSubscription: Subscription;
  modalRef: NgbModalRef;
  img: GetAllImages = new GetAllImages();
  newImg: any;
  fileNameText = 'Choose File';

  constructor(private modalBootstrap: NgbModal, public modalService: ModalService, private apiService: ApiService) { }

  ngOnInit() {
    this.openTimeoutModalSubscription = this.modalService.openUpdateIMGModalBS.subscribe(open => {
      if (open.flag) {
        this.img = open.img;
        this.newImg = null;
        this.openVerticallyCentered();
      }
    });

    this.modalService.closeAllModalsBS.subscribe((flag) => {
      if (flag && this.modalRef) {
        this.modalRef.close();
      }
    });

  }


  openVerticallyCentered() {
    this.modalRef = this.modalBootstrap.open(this.content, { centered: true });
    this.modalRef.result.then((result) => {
      if (this.newImg) {
        const req = {
          imageId: this.img.imageId,
          image: this.newImg,
          name: this.img.name
        }
        this.apiService.updateImage(req).subscribe(data => {
          console.log(data);
          this.modalService.closeUpdateIMGModal(false, true);
          this.newImg = null;
        });
      } else {
        this.modalService.closeUpdateIMGModal(false, true);
      }
    }).catch((result) => { //dismiss
      this.fileNameText = 'Choose File';    //reset both fileNameText and newImg on cancel or navigate away from modal
      this.newImg = null;
      this.modalService.closeUpdateIMGModal(false, false);
    });
  }

  onFileChange(files: FileList) {

    if (files.length > 0) {
      this.fileNameText = Array.from(files)
        .map(f => f.name)
        .join(', ');

      const rawFile = files.item(0);

      const fileReader = new FileReader();

      fileReader.readAsDataURL(rawFile);

      fileReader.onload = (e: any) => {
        // all logic should go here

        this.newImg = e.target.result;
      }
    } else {
      this.fileNameText = 'Choose File';
    }
  }

  ngOnDestroy() {
    this.openTimeoutModalSubscription.unsubscribe();
  }
}
