import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer
export const FULL_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: './changelog/changelog.module#ChangeLogModule'
  },
  {
    path: 'full-layout',
    loadChildren: './pages/full-layout-page/full-pages.module#FullPagesModule'
  },
  {
    path: 'alerts',
    loadChildren: './features/alerts/alerts.module#AlertsModule'
  },
  {
    path: 'developer',
    loadChildren: './features/developer/developer.module#DeveloperModule'
  },
  {
    path: 'resources',
    loadChildren: './features/resources/resources.module#ResourcesModule'
  }
];
