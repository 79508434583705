import * as UserReducer from './user/user.reducer';
import * as ConfigReducer from './config/config.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface State {
  userState: UserReducer.State;
  configState: ConfigReducer.State;
}

export const reducers: ActionReducerMap<State> = {
  userState: UserReducer.reducer,
  configState: ConfigReducer.reducer
};
