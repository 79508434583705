import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../core/services/modal.service';
import { GetAllPdfs } from '../../../model/getallpdfs.response';
import { ApiService } from '../../../core/services/api.service';
import { Card } from '../../../model/resource-links.response.model';

@Component({
  selector: 'app-resource-card-popup',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss']
})
export class ResourceCardComponent implements OnInit, OnDestroy {


  @ViewChild('content', { static: true }) private content;
  openCardModalSubscription: Subscription;
  modalRef: NgbModalRef;
  card: Card;
  type = '';
  newPdf: any;
  fileNameText = 'Choose File';

  constructor(private modalBootstrap: NgbModal, public modalService: ModalService, private apiService: ApiService) { }

  ngOnInit() {
    this.openCardModalSubscription = this.modalService.openResourceCardModalBS.subscribe(open => {
      if (open.flag) {
        if (!open.card) {
          // new card being added
          this.card = new Card();
          this.type = 'NEW'
        } else {
          this.card = open.card;
          console.log(this.card);
          this.type = 'UPDATE'
        }
        this.newPdf = null;
        this.openVerticallyCentered();
      }
    });

    this.modalService.closeAllModalsBS.subscribe((flag) => {
      if (flag && this.modalRef) {
        this.modalRef.close();
      }
    });

  }


  openVerticallyCentered() {
    this.modalRef = this.modalBootstrap.open(this.content, { centered: true });
    this.modalRef.result.then((result) => {
      if (this.newPdf) {
        const req = {
          resourceId: this.card.resourceContextId,
          resourceStateId: 'stateId',
          pdf: this.newPdf,
          resourceType: 'pdf',
          languageCode: 'en-us',
          paragraphText: 'description',
          linkTitle: 'title',
          link: null,
          displayOrder: null
        }

        console.log(req);
        // this.apiService.updatePdf(req).subscribe(data => {
        //   console.log(data);
        //   this.modalService.closeUpdatePDFModal(false, true);
        //   this.newPdf = null;
        // });
      } else {
        this.modalService.closeResourceCardModal(false, true);
      }
    }).catch((result) => { //dismiss
      this.fileNameText = 'Choose File';    //reset both fileNameText and newPdf on cancel or navigate away from modal
      this.newPdf = null;
      this.modalService.closeResourceCardModal(false, false);
    });
  }

  onFileChange(files: FileList) {

    if (files.length > 0) {
      this.fileNameText = Array.from(files)
        .map(f => f.name)
        .join(', ');

      const rawFile = files.item(0);

      const fileReader = new FileReader();

      fileReader.readAsDataURL(rawFile);

      fileReader.onload = (e: any) => {
        // all logic should go here

        const beforeFormat: string = e.target.result;

        this.newPdf = beforeFormat.slice(beforeFormat.search(',') + 1); //slice the base64 value only
      }
    } else {
      this.fileNameText = 'Choose File';
    }
  }

  previewPdf() {

    // decode base64 string, remove space for IE compatibility
    const binary = atob(this.newPdf.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    let view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"               
    const blob = new Blob([view], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    window.open(url);
  }

  ngOnDestroy() {
    this.openCardModalSubscription.unsubscribe();
  }
}
