import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { ResourcesMenuResponse } from '../../model/resources-menu.response.model'

@Injectable()
export class ResourcesService {

  public resources: BehaviorSubject<ResourcesMenuResponse[]> = new BehaviorSubject<ResourcesMenuResponse[]>([]);

  constructor(private apiService: ApiService) { }

  getResourcesMenu(request) {
    this.apiService.getResourcesMenu(request).subscribe(menu => {
      this.resources.next(menu);
    });
  }

}
