import * as ConfigActions from './config.actions';

export interface State {
    loading: boolean;
}

const InitialState: State = {
   loading: false
};

export function reducer(state = InitialState, action: ConfigActions.All): State {
  
    switch (action.type) {
    case ConfigActions.START_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case ConfigActions.STOP_LOADING: {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
