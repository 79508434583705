import * as UserActions from './user.actions';
import { UserResponse } from '../../model/user.response';
import { SystemConfig } from 'app/model/system-config.response';

export interface State {
  isAuthenticated: boolean;
  user: UserResponse;
  error: string;
  success: boolean;
  systemConfig: SystemConfig;
  sessionExpired: boolean;
  appVersion: string;
}

const InitialState: State = {
  user: undefined,
  systemConfig: undefined,
  isAuthenticated: false,
  error: '',
  success: false,
  sessionExpired: false,
  appVersion: ''
};

export function reducer(state = InitialState, action: UserActions.All): State {
  switch (action.type) {
    case UserActions.LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload.response,
        isAuthenticated: true,
        success: true
      };
    }

    case UserActions.CHOOSE_CLIENT_SUCCESS: {
      return {
        ...state,
        systemConfig: action.payload
      };
    }

    case UserActions.LOGIN_FAILURE: {
      return {
        ...state,
        success: false,
        isAuthenticated: false,
        error: action.payload.response
      };
    }

    case UserActions.UPDATE_VERSION: {
      return {
        ...state,
        appVersion: action.payload
      };
    }

    case UserActions.LOGOUT_SUCCESS: {
      return {
        ...state,
        user: undefined,
        isAuthenticated: false,
        error: '',
        success: false,
        sessionExpired: false
      };
    }

    default: {
      return state;
    }
  }
}
