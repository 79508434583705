import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../core/services/modal.service';
import { GetAllImages } from '../../../model/getallimages.response';
import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-deleteimage-popup',
  templateUrl: './deleteimage.component.html',
  styleUrls: ['./deleteimage.component.scss']
})
export class DeleteImageComponent implements OnInit, OnDestroy {


  @ViewChild('content', { static: true }) private content;
  openTimeoutModalSubscription: Subscription;
  modalRef: NgbModalRef;
  img: GetAllImages = new GetAllImages();
  confirmed: boolean;

  constructor(private modalBootstrap: NgbModal, public modalService: ModalService, private apiService: ApiService) { }

  ngOnInit() {
    this.openTimeoutModalSubscription = this.modalService.openDeleteIMGModalBS.subscribe(open => {
      if (open.flag) {
        this.img = open.img;
        this.confirmed=false;
        this.openVerticallyCentered();
      }
    });

    this.modalService.closeAllModalsBS.subscribe((flag) => {
      if (flag && this.modalRef) {
        this.modalRef.close();
      }
    });

  }


  openVerticallyCentered() {
    this.modalRef = this.modalBootstrap.open(this.content, { centered: true });
    this.modalRef.result.then((result) => {
      if (this.confirmed) {
        const req = {
          imageId: this.img.imageId
        }
        this.apiService.deleteImage(req).subscribe(data => {
          console.log(data);
          this.modalService.closeDeleteIMGModal(false, true);
          this.confirmed = false;
        });
      } else {
        this.modalService.closeDeleteIMGModal(false, true);
      }
    }).catch((result) => { //dismiss
      this.confirmed= false;    //reset both fileNameText and newImg on cancel or navigate away from modal
      this.modalService.closeDeleteIMGModal(false, false);
    });
  }

  setConfirmed(){
    this.confirmed=true;
  }

  ngOnDestroy() {
    this.openTimeoutModalSubscription.unsubscribe();
  }
}
