import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/alerts',
  //   title: 'Alerts',
  //   icon: 'icon-energy',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: []
  // },
  {
    path: '/full-layout',
    title: 'Home',
    icon: 'icon-screen-desktop',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '/resources',
    title: 'Resources',
    icon: 'icon-list',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },
  // {
  //   path: '/pages',
  //   title: 'Pages',
  //   icon: 'icon-grid',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'Menu Levels',
  //   icon: 'icon-layers',
  //   class: 'has-sub',
  //   badge: '1',
  //   badgeClass: 'badge badge-pill badge-danger float-right mr-3 mt-1',
  //   isExternalLink: false,
  //   submenu: [
  //     {
  //       path: 'javascript:;',
  //       title: 'Second Level',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: true,
  //       submenu: []
  //     },
  //     {
  //       path: '',
  //       title: 'Second Level',
  //       icon: '',
  //       class: 'has-sub',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: [
  //         {
  //           path: 'javascript:;',
  //           title: 'Third Level 1.1',
  //           icon: '',
  //           class: '',
  //           badge: '',
  //           badgeClass: '',
  //           isExternalLink: true,
  //           submenu: []
  //         },
  //         {
  //           path: 'javascript:;',
  //           title: 'Third Level 1.2',
  //           icon: '',
  //           class: '',
  //           badge: '',
  //           badgeClass: '',
  //           isExternalLink: true,
  //           submenu: []
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '',
    title: 'Developer Tool',
    icon: 'icon-settings',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/developer/system-config',
        title: 'System Config',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/developer/program-colors',
        title: 'Program Colors',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/developer/system-defaults',
        title: 'System Defaults',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/developer/program-defaults',
        title: 'Program Defaults',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/developer/getallpdfs',
        title: 'PDF updater',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/developer/getallimages',
        title: 'Image updater',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      }
    ]
  }
  // {
  //   path: '/changelog', title: 'ChangeLog',
  //   icon: 'icon-doc', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //   path: 'http://pixinvent.com/demo/convex-angular-bootstrap-admin-dashboard-template/documentation',
  //   title: 'Documentation',
  //   icon: 'icon-book-open', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []
  // }
];
