
import { throwError as observableThrowError, Observable, Subscription, Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { timer } from 'rxjs/observable/timer';
import { Injectable, OnDestroy } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { map, catchError, flatMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import * as UserActions from '../../store/user/user.actions';
import { environment } from '../../../environments/environment';
import { ApiService } from 'app/core/services/api.service';

@Injectable()
export class AuthService implements OnDestroy {

  private sessionTime = 5400000;
  private warningTime = 60000;
  // private sessionTime = 12000;
  // private warningTime = 5000;

  public token: string;
  private sessionTimer: Observable<number>;
  public openTimeoutModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public timeoutExpiring: Subject<number> = new Subject<number>();

  // subscriptions
  private tokenTimerSubscription: Subscription;
  private sessionTimerSubscription: Subscription;
  private resetInactivitySubscription: Subscription;
  private appVersionSubscription: Subscription;
  private tokenApiCallSubscription: Subscription;

  constructor(private apiService: ApiService, private jwtHelper: JwtHelperService,
    private router: Router, private store: Store<fromRoot.State>) { }


  public startInactivityTimer() {

    if (this.resetInactivitySubscription) {
      this.resetInactivitySubscription.unsubscribe();
    }
    const clicks$ = fromEvent(document, 'click');
    this.resetInactivitySubscription = clicks$.subscribe(() => {
      this.startSessionTimer();
    });
  }

  public startSessionTimer() {
    this.cancelSessionTimer();
    this.sessionTimer = timer(this.sessionTime - this.warningTime, 1000).pipe(
      map((value) => {
        return this.warningTime - value * 1000;
      }));

    this.sessionTimerSubscription = this.sessionTimer.subscribe(n => {

      this.timeoutExpiring.next(n);
      if (n === 0) {
        this.cancelAllSubscriptions();
        this.store.dispatch(new UserActions.LogOutAction);
      }
      if (!this.openTimeoutModal.getValue() && n !== 0) {
        this.openTimeoutModal.next(true);
      }

      //  localStorage.removeItem('token');
      //  this.router.navigate(['/login']);
    });

  }

  public cancelSessionTimer() {
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
      this.openTimeoutModal.next(false);
    }
  }


  ////////////////////////////////////////////////////////////////////////////
  // TOKEN STUFFFFFF
  ////////////////////////////////////////////////////////////////////////////

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('userState'));

    if (token && user.isAuthenticated) {
      try {
        const tokenExpiration = this.jwtHelper.getTokenExpirationDate(token);
        return Date.now() < tokenExpiration.getTime();
      } catch (error) {
        localStorage.removeItem('token');
        return false;
      }
    }
    return false;
  }

  public scheduleTokenRenewal() {

    // cancel any token renewal subscriptions
    this.cancelTokenRenewal();

    // check authentication
    if (!this.isAuthenticated()) {
      this.cancelAllSubscriptions();
      this.store.dispatch(new UserActions.LogOutAction);
      return;
    }

    const token = localStorage.getItem('token');

    const tokenExpiration = this.jwtHelper.getTokenExpirationDate(token);
    const now = Date.now();
    // when does it actually call?? is there a buffer before the call to the api?
    const expiresIn$ = timer(Math.max(1, (tokenExpiration.getTime() - 60000) - now), 1000);

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.tokenTimerSubscription = expiresIn$.subscribe(
      () => {
        this.renewToken();
      }
    );
  }

  public cancelTokenRenewal() {
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
  }

  public cancelAllSubscriptions() {
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
      this.openTimeoutModal.next(false);
    }
    if (this.resetInactivitySubscription) {
      this.resetInactivitySubscription.unsubscribe();
    }
  }

  public renewToken() {
    // Dispatch calling refresh token
    const token = localStorage.getItem('token');

    if (this.tokenApiCallSubscription) {
      this.tokenApiCallSubscription.unsubscribe();
    }

    this.tokenApiCallSubscription = this.apiService.refreshToken(token).subscribe(data => {
      // If successful, dispatch success action with result
      localStorage.setItem('token', data.token);
      this.scheduleTokenRenewal();
    });
  }


  /////////////////////////////////////////////
  ////// check app version ////////////////////

  checkAppVersion() {

    if (this.appVersionSubscription) {
      this.appVersionSubscription.unsubscribe();
    }

    const versionObservable$ = this.store.select(state => state.userState.appVersion);

    versionObservable$.subscribe(version => {
      if (version !== environment.version) {
        console.log(environment.version);
        console.log('version doesnt match');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
    }
    if (this.resetInactivitySubscription) {
      this.resetInactivitySubscription.unsubscribe();
    }
    if (this.appVersionSubscription) {
      this.appVersionSubscription.unsubscribe();
    }
    if (this.tokenApiCallSubscription) {
      this.tokenApiCallSubscription.unsubscribe();
    }
  }


}
