import { ApiService } from './services/api.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/config.service';
import { ModalService } from './services/modal.service';
import { ResourcesService } from './services/resources.service';

@NgModule(
  {
    declarations: [],
    imports: [CommonModule],
    exports: []
  })
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [ApiService, ResourcesService, ConfigService, ModalService]
    };
  }
}
