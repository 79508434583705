import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserResponse } from '../../model/user.response';
import { ResourceLinksResponse } from '../../model/resource-links.response.model';



@Injectable()
export class ApiService {

  URL = 'http://10.10.6.123:8082';

  constructor(private http: HttpClient) {
    this.URL = environment.apiUrl;
  }

  login(request: any) {
    return this.http
      .post<UserResponse>(`${this.URL}/loginadmin`, request)
      .pipe(catchError(this.errorHandler));
  }

  generatePdf(pdfId: string) {
    const request = {
      id: pdfId
    };
    return this.http
      .post(`${this.URL}/getpdf`, request, { responseType: 'blob' as 'json' })
      .pipe(
        map((res: any) => {
          return new Blob([res], { type: 'application/pdf' });
        })
      );
  }

  getAllImages(req: any) {
    return this.http
      .post<any>(`${this.URL}/getallimages`, req)
      .pipe(catchError(this.errorHandler));
  }

  updateImage(req: any) {
    return this.http
      .post<any>(`${this.URL}/updateimage`, req)
      .pipe(catchError(this.errorHandler));
  }
  deleteImage(req: any) {
    return this.http
      .post<any>(`${this.URL}/deleteimage`, req)
      .pipe(catchError(this.errorHandler));
  }
  addImage(req: any) {
    return this.http
      .post<any>(`${this.URL}/addimage`, req)
      .pipe(catchError(this.errorHandler));
  }

  getAllPdfs(req: any) {
    return this.http
      .post<any>(`${this.URL}/getallpdfs`, req)
      .pipe(catchError(this.errorHandler));
  }

  updatePdf(req: any) {
    return this.http
      .post<any>(`${this.URL}/updatepdf`, req)
      .pipe(catchError(this.errorHandler));
  }

  getProgramDefaults(req: any) {
    return this.http
      .post<any>(`${this.URL}/getprogramdefaults`, req)
      .pipe(catchError(this.errorHandler));
  }

  getSystemConfig(req: any) {
    return this.http
      .post<any>(`${this.URL}/getsystemconfig`, req)
      .pipe(catchError(this.errorHandler));
  }

  getSystemDefaults(req: any) {
    return this.http
      .post<any>(`${this.URL}/getsystemdefaults`, req)
      .pipe(catchError(this.errorHandler));
  }

  getProgramColors(req: any) {
    return this.http
      .post<any>(`${this.URL}/getprogramcolors`, req)
      .pipe(catchError(this.errorHandler));
  }

  updateSystemConfig(req: any) {
    return this.http
      .post<any>(`${this.URL}/updatesystemconfig`, req)
      .pipe(catchError(this.errorHandler));
  }

  updateSystemDefaults(req: any) {
    return this.http
      .post<any>(`${this.URL}/updatesystemdefaults`, req)
      .pipe(catchError(this.errorHandler));
  }

  updateProgramDefaults(req: any) {
    return this.http
      .post<any>(`${this.URL}/updateprogramdefaults`, req)
      .pipe(catchError(this.errorHandler));
  }

  updateProgramColors(req: any) {
    return this.http
      .post<any>(`${this.URL}/updateprogramcolors`, req)
      .pipe(catchError(this.errorHandler));
  }


  getResourcesMenu(request: any): any {
    return this.http.post<any>(`${this.URL}/getresourcesmenu`, request)
      .pipe(catchError(this.errorHandler));

  }

  getResourceLinks(request: any) {
    return this.http.post<ResourceLinksResponse>(`${this.URL}/getresourcelinks`, request)
      .pipe(catchError(this.errorHandler));
  }

  addResourceLink(request: any) {
    return this.http.post<any>(`${this.URL}/addresourcelink`, request)
      .pipe(catchError(this.errorHandler));
  }

  updateSummary(request: any) {
    return this.http.post<any>(`${this.URL}/updatesummary`, request)
      .pipe(catchError(this.errorHandler));
  }


  //not sure if following route will be required (ported from DaVinci during DAV-379)
  generateResourcePDF(pdfId: string) {

    const request = {
      id: pdfId
    };
    return this.http.post(`${this.URL}/getpdf`, request, { responseType: 'blob' as 'json' })
      .pipe(map((res: any) => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }




  refreshToken(token: string) {
    return this.http
      .get<any>(`${this.URL}/refreshtoken`)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error);
  }
}
