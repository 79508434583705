
import { throwError as observableThrowError, Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { GetAllPdfs } from '../../model/getallpdfs.response';
import { GetAllImages } from '../../model/getallimages.response';
import { Card } from '../../model/resource-links.response.model';

@Injectable()
export class ModalService {

    // tslint:disable-next-line: max-line-length
    public openUpdatePDFModalBS: BehaviorSubject<any> = new BehaviorSubject<any>({ flag: false, pdf: undefined, success: false, uploaded: false });
    // tslint:disable-next-line: max-line-length
    public openUpdateIMGModalBS: BehaviorSubject<any> = new BehaviorSubject<any>({ flag: false, img: undefined, success: false, uploaded: false });
    public openAddIMGModalBS: BehaviorSubject<any> = new BehaviorSubject<any>({ flag: false, success: false, uploaded: false });
    // tslint:disable-next-line: max-line-length
    public openDeleteIMGModalBS: BehaviorSubject<any> = new BehaviorSubject<any>({ flag: false, img: undefined, success: false, uploaded: false });

    // tslint:disable-next-line:max-line-length
    public openResourceCardModalBS: BehaviorSubject<any> = new BehaviorSubject<any>({ flag: false, card: undefined, success: false, uploaded: false });

    public closeAllModalsBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }

    openUpdatePDFModal(pdf: GetAllPdfs, success: boolean) {
        this.openUpdatePDFModalBS.next({ flag: true, pdf: pdf, success: success, uploaded: false });
    }

    closeUpdatePDFModal(success: boolean, uploaded: boolean) {
        this.openUpdatePDFModalBS.next({ flag: false, pdf: undefined, success: success, uploaded: uploaded });
    }

    openResourceCardModal(card: Card, success: boolean) {
        this.openResourceCardModalBS.next({ flag: true, card: card, success: success, uploaded: false });
    }

    closeResourceCardModal(success: boolean, uploaded: boolean) {
        this.openResourceCardModalBS.next({ flag: false, card: undefined, success: success, uploaded: uploaded });
    }

    openUpdateIMGModal(img: GetAllImages, success: boolean) {
        this.openUpdateIMGModalBS.next({ flag: true, img: img, success: success, uploaded: false });
    }

    closeUpdateIMGModal(success: boolean, uploaded: boolean) {
        this.openUpdateIMGModalBS.next({ flag: false, img: undefined, success: success, uploaded: uploaded });
    }
    openAddIMGModal(success: boolean) {
        this.openAddIMGModalBS.next({ flag: true, success: success, uploaded: false });
    }

    closeAddIMGModal(success: boolean, uploaded: boolean) {
        this.openAddIMGModalBS.next({ flag: false, success: success, uploaded: uploaded });
    }

    openDeleteIMGModal(img: GetAllImages, success: boolean) {
        this.openDeleteIMGModalBS.next({ flag: true, img: img, success: success, uploaded: false });
    }

    closeDeleteIMGModal(success: boolean, uploaded: boolean) {
        this.openDeleteIMGModalBS.next({ flag: false, img: undefined, success: success, uploaded: uploaded });
    }



    closeAllModals() {
        this.closeAllModalsBS.next(true);
        this.openUpdatePDFModalBS.next({ flag: false, pdf: undefined, success: true, uploaded: false });
        this.openResourceCardModalBS.next({ flag: false, pdf: undefined, success: true, uploaded: false });
        this.openUpdateIMGModalBS.next({ flag: false, img: undefined, success: true, uploaded: false });
        this.openAddIMGModalBS.next({ flag: false, success: true, uploaded: false });
        this.openDeleteIMGModalBS.next({ flag: false, img: undefined, success: true, uploaded: false });

    }
}
